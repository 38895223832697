<template>
  <div class="simple-start-journey">
    <div class="b-banner" />

    <div class="b__info">
      <SimpleLandingLogo
        class="simple-landing-logo--mobile"
        :corporate="corporate"
      />
      <h2 class="b__header-primary">
        {{ $t('b2b__simple_landing_page__start_journey__title') }}
      </h2>
      <h2 class="b__header-secondary">
        {{ $t('b2b__simple_landing_page__start_journey__slogan') }}
      </h2>
      <span class="b__description">
        {{ $t('b2b__simple_landing_page__start_journey__description') }}
      </span>
      <BaseButton
        :text="ctaBtnTitle"
        @click="handleCTAButtonClick"
      />

      <SimpleLandingFooter
        class="b__footer b__footer--desktop"
        :corporate="corporate"
        :lang="lang"
      />
    </div>

    <SimpleLandingFooter
      class="b__footer b__footer--mobile"
      :corporate="corporate"
      :lang="lang"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import BaseButton from '@shared/components/BaseButton.vue'
import SimpleLandingFooter from '@msk-us/components/SimpleLandingFooter.vue'
import SimpleLandingLogo from '@msk-us/components/SimpleLandingLogo.vue'
import Corporate from '@shared/data/models/corporate.js'

export default {
  name: 'SimpleStartJourney',
  components: {
    BaseButton,
    SimpleLandingFooter,
    SimpleLandingLogo,
  },
  props: {
    ctaBtnTitle: {
      type: String,
      default: '',
    },
    corporate: {
      type: Corporate,
      default: null,
    },
    handleCTAButtonClick: {
      type: Function,
      default: () => {},
    },
    isGerman: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapGetters('b2b', ['corporate']),
    ...mapGetters(['lang']),
  },
}
</script>

<style lang="scss">
@import '@msk-us/components/css/SimpleStartJourney.scss';
</style>
