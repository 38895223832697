<!--
INFO: There are two version (EU and Shared)

src/eu/app/views/DVGCodeVerification.vue
src/us/msk/views/B2BVoucher.vue
src/us/msk/views/B2BVirginpulseSSOCompleted.vue
src/eu/app/views/PrescriberInformation.vue
src/eu/app/views/PrescriberInformation.vue
src/eu/app/views/PrescriberInformation.vue
src/eu/app/components/InsuranceSearchSection.vue
src/eu/app/components/PasswordField.vue
src/eu/app/components/EmailField.vue
src/eu/app/components/InsuranceVerificationSection.vue
src/eu/app/components/PublicActivationField.vue
-->

<template>
  <text-input
    :id="id"
    :input-name="inputName"
    :input-label="inputLabel"
    :default-value="defaultValue"
    :state="stateComputed"
    :autofocus="autofocus"
    :autocomplete="autocomplete"
    :error-message="errorMessageComputed"
    :error-code="errorCodeComputed"
    :data-q-a="dataQA"
    :placeholder="placeholder"
    @input="handleInputEvent"
    @blur="handleBlurEvent"
    @focus="handleFocusEvent"
    @keyup="handleKeyUpEvent"
    @click="handleClickEvent"
  />
</template>

<script>
import TextInput from '@shared/components/TextInput.vue'
import { useI18n } from 'vue-i18n'

const ERRORS = {
  EMPTY: 1,
}

const getErrorCode = (value) => (value ? 0 : ERRORS.EMPTY)

export default {
  name: 'TextInputField',
  components: {
    TextInput,
  },
  inheritAttrs: false,
  props: {
    id: {
      type: String,
      default: null,
    },
    inputName: {
      type: String,
      default: '',
    },
    inputLabel: {
      type: String,
      default: '',
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
    autocomplete: {
      type: Boolean,
      default: false,
    },
    defaultValue: {
      type: String,
      default: '',
    },
    errorMessage: {
      type: Function,
      default: () => '',
    },
    errorCode: {
      type: Number,
      default: 0,
    },
    showErrors: {
      type: Boolean,
      default: false,
    },
    dataQA: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
  },
  emits: ['input', 'click', 'focus', 'blur', 'keyup'],
  setup() {
    const { t } = useI18n()
    return { t }
  },
  data() {
    return {
      nextValue: '',
    }
  },
  computed: {
    defaultErrorMessageComputed() {
      switch (this.errorCodeComputed) {
        case ERRORS.EMPTY:
          return this.t('generic_field_required', {
            fieldTitle: this.inputLabel,
          })
        default:
          return ''
      }
    },
    errorMessageComputed() {
      return (
        this.errorMessage({
          name: this.inputName,
          errorCode: this.errorCodeComputed,
        }) || this.defaultErrorMessageComputed
      )
    },
    stateComputed() {
      return this.errorCodeComputed === 0
    },
    errorCodeComputed() {
      if (!this.showErrors) {
        return 0
      }

      if (this.errorCode > 0) {
        return this.errorCode
      }

      return getErrorCode(this.nextValue)
    },
  },
  created() {
    this.nextValue = this.defaultValue

    this.$emit('input', {
      name: this.inputName,
      value: this.nextValue,
      state: getErrorCode(this.nextValue) === 0,
    })
  },
  methods: {
    handleClickEvent(event) {
      this.$emit('click', event)
    },
    handleInputEvent(event) {
      this.nextValue = event.value.trim()
      const nextErrorCode = getErrorCode(this.nextValue)

      this.$emit('input', {
        name: this.inputName,
        value: this.nextValue,
        state: nextErrorCode === 0,
      })
    },
    handleFocusEvent({ event, value }) {
      this.nextValue = value.trim()
      const nextErrorCode = getErrorCode(this.nextValue)

      this.$emit('focus', {
        name: this.inputName,
        value: this.nextValue,
        state: nextErrorCode === 0,
        event,
      })
    },
    handleBlurEvent({ event, value }) {
      this.nextValue = value.trim()
      const nextErrorCode = getErrorCode(this.nextValue)

      this.$emit('blur', {
        name: this.inputName,
        value: this.nextValue,
        state: nextErrorCode === 0,
        event,
      })
    },
    handleKeyUpEvent(event) {
      this.$emit('keyup', event)
    },
  },
}
</script>
