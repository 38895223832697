<template>
  <form
    class="voucher-content"
    @submit.prevent="verifyVoucher"
  >
    <MobileHeader />
    <div class="content">
      <CorporateHeader />
      <div class="content-header">
        <p
          class="kaia-p3-bold"
          v-html="$t('b2b_voucher_welcome_text', { name: corporateName })"
        />
      </div>
      <div class="instructions">
        <p v-html="$t('b2b_voucher_instructions_intro')" />
        <div class="step">
          <h4 v-html="$t('b2b_voucher_instructions_step_1_title')" />
          <p v-html="$t('b2b_voucher_instructions_step_1_text')" />
        </div>
        <div class="step">
          <h4 v-html="$t('b2b_voucher_instructions_step_2_title')" />
          <p v-html="$t('b2b_voucher_instructions_step_2_text')" />
        </div>
        <div class="step">
          <h4 v-html="$t('b2b_voucher_instructions_step_3_title')" />
          <p v-html="$t('b2b_voucher_instructions_step_3_text')" />
        </div>
      </div>
      <div class="voucher-action">
        <div class="voucher-field-group">
          <TextInputField
            input-name="voucher_code"
            :input-label="$t('b2b_voucher_placeholder')"
            :autocomplete="false"
            :autofocus="true"
            :default-value="voucherCode"
            :error-message="getErrorMessage"
            :error-code="voucherErrorCode"
            :show-errors="showErrors"
            data-q-a="voucher-code"
            @input="handleTextInputChange"
          />
        </div>
        <div class="interactive-button-regular">
          <BaseButton
            :text="$t('b2b_voucher_submit')"
            data-qa="verify-voucher"
            :disabled="loadingButtonState === 'DISABLED'"
            :loading="loadingButtonState === 'PROCESSING'"
            @click="verifyVoucher"
          />
        </div>
      </div>
      <div class="language-layout">
        <LanguageSwitcher />
      </div>
    </div>
  </form>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import CorporateHeader from '@shared/components/CorporateHeader.vue'
import { createHelpers } from '@shared/vuex-utils'
import ROUTE from '@msk-us/router/names'
import MobileHeader from '@shared/components/MobileHeader.vue'
import TextInputField from '@shared/components/TextInputField.vue'
import BaseButton from '@shared/components/BaseButton.vue'
import LanguageSwitcher from '@shared/components/LanguageSwitcher.vue'
import { useI18n } from 'vue-i18n'

const { mapFields } = createHelpers({
  getterType: 'b2b/getField',
  mutationType: 'b2b/updateField',
})

export default {
  name: 'B2BVoucher',
  components: {
    MobileHeader,
    CorporateHeader,
    TextInputField,
    BaseButton,
    LanguageSwitcher,
  },
  props: {
    configureStickyHeader: {
      type: Function,
      default: () => {},
    },
  },
  setup() {
    const { t } = useI18n()
    return { t }
  },
  data() {
    return {
      voucherCodeState: false,
      voucherErrorCode: 0,
      showErrors: false,
      loadingButtonState: 'ACTIVE',
    }
  },
  computed: {
    ...mapFields(['formData.voucherCode']),
    ...mapGetters('b2b', ['voucher', 'corporate', 'verificationParams']),
    corporateName() {
      return this.corporate.title || ''
    },
    isFormValid() {
      return this.voucherCodeState
    },
  },
  created() {
    const code =
      this.$route.params?.voucherCode || this.verificationParams.voucher

    if (code) {
      this.voucherCode = code
      this.voucherCodeState = true
      this.verifyVoucher()
    }
  },
  methods: {
    ...mapActions('b2b', ['checkVoucher']),
    redirectToNext(voucher) {
      this.loadingButtonState = 'ACTIVE'
      this.$router.push({
        name: ROUTE.B2B_SIGN_UP,
        params: {
          corp_name: voucher.corporate_name,
        },
      })
    },
    verifyVoucher(e) {
      if (e) {
        e.preventDefault()
      }
      this.showErrors = false

      if (!this.isFormValid) {
        this.showErrors = true
        return false
      }

      this.loadingButtonState = 'PROCESSING'

      this.checkVoucher(this.voucherCode)
        .then(this.redirectToNext)
        .catch((error) => {
          this.loadingButtonState = 'ACTIVE'
          if (error.response && error.response.status === 403) {
            this.showErrors = true
            this.voucherErrorCode = 403
          }
        })
    },
    addEmailToWaitingList(mail) {
      // TODO: after BE ready call endpoint to put the email into waiting list
      console.log(mail)
    },
    handleTextInputChange({ name, value, state }) {
      this.voucherErrorCode = 0
      switch (name) {
        case 'voucher_code':
          this.voucherCode = value
          this.voucherCodeState = state
          break

        default:
          break
      }
    },
    getErrorMessage({ errorCode }) {
      if (errorCode <= 0) {
        return ''
      }

      if (this.voucherErrorCode === 403) {
        return this.t('generic_voucher_not_valid')
      }

      return this.t('generic_voucher_required')
    },
  },
}
</script>

<style lang="scss">
@import './css/B2BVoucher';
</style>
