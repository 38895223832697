<template>
  <!-- eslint-disable vue/no-v-html -->
  <UnauthenticatedLayout class="kds-main-wrapper">
    <BaseBanner
      v-if="loginErrorBanner"
      variant="warning"
      :title="loginErrorBanner.title"
      class="error content"
      scroll-into-view
    >
      <div class="kds-content-block">
        <p class="kds-typography-paragraph-medium">
          {{ loginErrorBanner.description }}
        </p>
        <BaseButton
          v-if="loginErrorBanner.showDownloadLinkButton"
          @click="router.push({ name: ROUTE.B2B_DOWNLOAD_LINK })"
        >
          {{ $t('generic_create_account') }}
        </BaseButton>
      </div>
    </BaseBanner>

    <div class="kds-content-block">
      <p
        class="kds-typography-display-small"
        v-html="$t('generic_login_with_existing_account')"
      />
      <p
        class="kds-typography-paragraph-large"
        v-html="$t('b2b_login_welcome_text', { name: corporate.title })"
      />
    </div>

    <div class="kds-content-block">
      <p
        class="kds-typography-title-medium"
        v-html="$t('b2b_login_account_block_title')"
      />
      <TextField
        v-bind="fieldAttrs?.email"
        type="email"
        autocomplete="email"
        :label="$t('generic_field_email')"
        data-qa="email"
      />

      <PasswordField
        v-bind="fieldAttrs.password"
        data-qa="password"
      />
      <BaseButton
        data-qa="submit"
        :loading="loading"
        :success="success"
        @click="submit"
      >
        {{ $t('generic_login') }}
      </BaseButton>
    </div>
    <NotRegistered />
  </UnauthenticatedLayout>
  <!-- eslint-enable vue/no-v-html -->
</template>

<script setup>
import { computed, ref } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import { logE, logW } from '@shared/utils'

import useForm from '@shared/composables/useForm.js'

import { B2B_AUTH_METHODS } from '@msk-us/router/b2b-hooks'
import ROUTE from '@msk-us/router/names'
import Tracker from '@shared/Tracker'

import UnauthenticatedLayout from '@msk-us/layouts/UnauthenticatedLayout.vue'

import NotRegistered from '@msk-us/components/NotRegistered.vue'
import BaseBanner from '@shared/components/BaseBanner.vue'
import BaseButton from '@shared/components/BaseButton.vue'
import PasswordField from '@shared/components/form/PasswordField.vue'
import TextField from '@shared/components/form/TextField.vue'

const trackConfig = {
  event_name: 'cb.onbrdg.create_account',
  app_area: 'onboarding',
  action: 'create',
  object_type: 'account',
  source: 'client_browser',
  screen_name: 'onb_account_creation',
}

const { t } = useI18n()
const store = useStore()
const router = useRouter()

const corporate = computed(() => store.getters['b2b/corporate'])

const loginErrorBanner = ref(null)

const kaiaSubmissionTrackEvent = (success, errorMessage, eventObj) => {
  const utmData = store.getters['b2b/utmData']

  const custom_payload = {
    search_success: success,
    corporate_id: corporate.value.id,
    corporate_name: corporate.value.title,
    corporate_type: corporate.value.corporateTypes.toString(),
    ...(corporate.value.channel
      ? { corporate_channel: corporate.value.channel }
      : null),
    ...(errorMessage ? { error_message: errorMessage } : null),
    ...(utmData.medium ? { utm_medium: utmData.medium } : null),
    ...(utmData.source ? { utm_source: utmData.source } : null),
    ...(utmData.campaign ? { utm_campaign: utmData.campaign } : null),
    ...(utmData.content ? { utm_content: utmData.content } : null),
  }

  Tracker.trackKaiaEvent(eventObj, {
    custom_payload,
  })
}

const logSignUpError = (error, message) => {
  logW(
    error,
    [
      `corporate id: ${corporate.value.id}`,
      `corporate key: ${corporate.value.key}`,
      ...message,
    ].join('|'),
  )
}

const formData = computed(() => store.getters['b2b/formData'])

const form = ref({
  email: formData.value.email || '',
  password: formData.value.password || '',
})

const { fieldAttrs, loading, submit, success } = useForm(
  form,
  ref({
    email: { validations: ['required', 'email'] },
    password: { validations: ['required'] },
  }),
  {
    onSubmit: async () => {
      loginErrorBanner.value = null

      Tracker.trackKaiaEvent(trackConfig, {
        button_label: 'continue',
      })

      await store.dispatch('login', form.value)

      const { code } = store.getters['b2b/voucher'] || {}
      const { eligible_token, token, type } =
        store.getters['b2b/verificationParams'] || {}

      kaiaSubmissionTrackEvent(null, null, trackConfig)

      if (token) {
        await store.dispatch('b2b/subscribeUserWithInsuranceToken', {
          corporateKey: corporate.value.key,
          token: token,
          type: type,
        })
      } else if (code) {
        await store.dispatch('b2b/subscribeUserWithVoucher', { code })
      } else if (eligible_token) {
        await store.dispatch('b2b/subscribeUserWithEligibleToken', {
          corporate_key: corporate.value.key,
          eligible_token: token,
        })
      }
    },
    onSuccess: async () => {
      router.push({
        name: ROUTE.B2B_DOWNLOAD_LINK,
      })
    },
    onError: (error) => {
      const status = error?.response?.status
      const errors = error?.response?.data?.errors
      const url = error?.response?.config?.url

      switch (url) {
        case 'auth/sign_in/': {
          kaiaSubmissionTrackEvent(false, error, trackConfig)
          logSignUpError('SIGN UP ERROR', [
            `http status: ${error?.response?.status}`,
            `http status text: ${error?.response?.statusText}`,
          ])

          switch (true) {
            case status === 400 && errors?.[0] === 'ALREADY_SUBSCRIBED':
              loginErrorBanner.value = {
                title: t('b2b__sponsor__label__activation_failed'),
                desc: t('b2c_common_error_token_conflict'),
                showDownloadLinkButton: true,
              }
              break
            case status === 401:
              logSignUpError('SIGN UP ERROR', ['No User After SignUp'])

              kaiaSubmissionTrackEvent(
                false,
                'no user object after signup',
                trackConfig,
              )

              break
            case status === 409:
              loginErrorBanner.value = {
                title: t('b2b__sponsor__label__activation_failed'),
                description: t('b2c_common_error_token_conflict'),
                showDownloadLinkButton: true,
              }
              break
            case corporate.value.preferredSignupMethod ===
              B2B_AUTH_METHODS.UHCHUB:
              loginErrorBanner.value = {
                title: t('b2b__sponsor__label__activation_failed'),
                description: t('b2b__uhchub_verification__description'),
              }
              break
            default:
              loginErrorBanner.value = {
                title: t('b2b__sponsor__label__activation_failed'),
                description: t(
                  'b2b__sponsor__label__kaia_pro_failed_to_activate_with_sponsor_huk_with_support_email',
                  {
                    VUE_APP_SUPPORT_EMAIL: import.meta.env.VITE_SUPPORT_EMAIL,
                  },
                ),
              }
          }
          break
        }
        default: {
          const { user } = store.getters

          logSignUpError('SIGN_UP_ERROR::SUBSCRIBE_USER_ERROR', [
            `user id: ${user.id}`,
            `http status: ${error?.response?.status}`,
            `http status text: ${error?.response?.statusText}`,
          ])

          logE(
            `creating subscription for user ${user.id} failed with code ${error?.response?.status}`,
          )
        }
      }
    },
    onFinally: () => {
      store.commit('b2b/clearInsuranceToken')
      store.commit('b2b/clearVoucher')
    },
  },
)
</script>

<style lang="scss">
:root {
  --base-banner--margin: 0;
}
</style>
