<template>
  <div class="simple-landing-footer">
    <div>
      <div class="b__label">
        {{ $t('b2b_static_contacts_name') }}
      </div>
      <div class="language-layout">
        <LanguageSwitcher />
      </div>
    </div>

    <div
      v-if="kaiaTermsEnabled"
      class="b__item"
    >
      <a
        href="#"
        class="b__link"
        :data-link="termsLink"
        data-custom="false"
        @click="onClickLink"
      >
        {{ staticTermsLink }}
      </a>
    </div>
    <div
      v-if="kaiaTermsEnabled"
      class="b__item"
    >
      <a
        href="#"
        class="b__link"
        :data-link="policyLink"
        data-custom="false"
        @click="onClickLink"
      >
        {{ staticPrivacyLink }}
      </a>
    </div>
    <div
      v-if="disclaimerText"
      class="b__item"
    >
      <a
        href="#"
        class="b__link"
        @click="openModal"
      >
        {{ staticDisclaimerLink }}
      </a>
    </div>
  </div>
</template>

<script>
import IframeModal from '@shared/components/IframeModal.vue'
import DisclaimerModal from '@shared/components/DisclaimerModal.vue'
import LanguageSwitcher from '@shared/components/LanguageSwitcher.vue'
import $modals from '@shared/modal/index'
import defineLanguage from '@shared/utils/defineLanguage'
import { useI18n } from 'vue-i18n'

export default {
  name: 'SimpleLandingFooter',
  components: { LanguageSwitcher },
  props: {
    corporate: {
      type: Object,
      required: true,
    },
    lang: {
      type: String,
      required: true,
    },
  },
  setup() {
    const { t } = useI18n()
    return { t }
  },
  computed: {
    kaiaTermsEnabled() {
      return this.corporate?.kaiaTermsEnabled
    },
    disclaimerText() {
      return this.corporate?.disclaimerEnabled
        ? this.corporate.disclaimerText
        : null
    },
    staticTermsLink() {
      return this.t('b2b_static_terms_conditions_link')
    },
    staticPrivacyLink() {
      return this.t('b2b_static_privacy_policy_link')
    },
    staticDisclaimerLink() {
      return this.t('b2b_static_disclaimer_link')
    },
    termsLink() {
      return this.staticLinks.termsLink
    },
    policyLink() {
      return this.staticLinks.policyLink
    },
    staticLinks() {
      const definedLang = defineLanguage(this.lang)
      const documentLang = definedLang === 'es' ? definedLang : 'us'
      const base = import.meta.env.VITE_PUBLIC_PATH
      return {
        termsLink: `${base}static/${documentLang}/terms/index.html`,
        policyLink: `${base}static/${documentLang}/privacy/index.html`,
      }
    },
  },
  methods: {
    onClickLink(e) {
      e.stopPropagation()
      const { link, custom } = e.target.dataset
      if (link) {
        e.preventDefault()
        if (custom === 'true') {
          window.open(link, '_blank')
        } else {
          this.showDocument(link)
        }
      }
    },
    showDocument(documentUrl) {
      $modals.open({
        component: IframeModal,
        props: {
          documentUrl,
        },
      })
    },
    openModal(e) {
      e.preventDefault()
      $modals.open({
        component: DisclaimerModal,
        props: {
          closeModal: this.closeModal,
          disclaimerText: this.disclaimerText || '',
        },
      })
    },
    closeModal(e) {
      e.preventDefault()
      $modals.close()
    },
  },
}
</script>

<style lang="scss">
@import '@msk-us/components/css/SimpleLandingFooter.scss';
</style>
